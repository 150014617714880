import React, {useState, useEffect} from "react";
import { Helmet } from 'react-helmet'

import {SmallHero, Header, Footer} from "../fragments";
import scrollTo from 'gatsby-plugin-smoothscroll';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDesktop, faCashRegister, faNewspaper, faTools } from '@fortawesome/free-solid-svg-icons'

import Docker from "../images/technos/docker.png";
import Express from "../images/technos/express.png";
import Firebase from "../images/technos/firebase.png";
import Gatsby from "../images/technos/gatsby.png";
import GoogleAnalytics from "../images/technos/googleanalytics.png";
import Mongo from "../images/technos/mongo.jpg";
import Node from "../images/technos/node.png";
import ReactLogo from "../images/technos/react.png";
import ReactNative from "../images/technos/reactnative.png";
import Sendinblue from "../images/technos/sendinblue.png";
import Strapi from "../images/technos/strapi.jpg";
import Woocommerce from "../images/technos/woocommerce.png";
import Wordpress from "../images/technos/wordpress.png";

import Divider from "../components/Divider";

const config = [
  {name: "e-commerce", icon: faCashRegister, technos: ["wordpress", "woocommerce", "google-analytics", "sendinblue", "docker"]},
  {name: "site vitrine / blog", icon: faDesktop, technos: ["wordpress", "gatsby", "react", "google-analytics", "sendinblue", "docker"]},
  {name: "application web sur mesure", icon: faTools, technos: ["react", "nodejs", "express", "strapi", "mongodb", "firebase", "docker"]},
  {name: "application mobile sur mesure", icon: faTools, technos: ["react-native", "nodejs", "express", "strapi", "mongodb", "firebase", "docker"]}
]

const technos = [
  {name: "docker"          , img: Docker,          tag: "devops"},
  {name: "express"         , img: Express,         tag: "backend"},
  {name: "firebase"        , img: Firebase,        tag: "backend"},
  {name: "gatsby"          , img: Gatsby,          tag: "frontend"},
  {name: "google-analytics", img: GoogleAnalytics, tag: "marketing"},
  {name: "mongodb"         , img: Mongo,           tag: "backend"},
  {name: "nodejs"          , img: Node,            tag: "backend"},
  {name: "react"           , img: ReactLogo,       tag: "frontend"},
  {name: "react-native"    , img: ReactNative,     tag: "frontend"},
  {name: "sendinblue"      , img: Sendinblue,      tag: "marketing"},
  {name: "strapi"          , img: Strapi,          tag: "backend"},
  {name: "woocommerce"     , img: Woocommerce,     tag: "full-stack"},
  {name: "wordpress"       , img: Wordpress,       tag: "full-stack"},
]

const Project = (props) => (
  <div className={`flex-1 flex flex-col items-center justify-center cursor-pointer select-none p-4 rounded ${props.selected ? "border-2 border-gray-400" : "border border-gray-300"}`}
       style={{gap: 10, minWidth: 250, height: 200}}
       onClick={props.onClick}>
    <FontAwesomeIcon icon={props.icon} size="3x" color={props.selected ? "#222" : "#444"} />
    <span className="text-xl text-center">{props.title}</span>
  </div>
)

const Solutions = () => {
  const [project, setProject] = useState(null);

  const _onProjectChange = (name) => {
    setProject(name);
    scrollTo("#technologiesContainer");
  }

  return (
    <main className="overflow-hidden">
      <Helmet>
        <title>Wisoftify - Nos solutions</title>
      </Helmet>
      <Header />
      <SmallHero title="Nos solutions" />
      <div className="bg-gray-100 px-3 pt-8 pb-6">
        <h2 className="text-center">Les dernières technologies au service de votre projet</h2>
        <p className="text-center mx-auto px-4 text-gray-700 mt-4" style={{maxWidth: 750, fontSize: 18}}>
          Chez Wisoftify nous avons à coeur de rester à la page sur les dernières technologies en vogue dans le monde du développement.<br/>
          Cet apprentissage permanent permet de réduire la <strong>dette technique</strong> et accélérer la <strong>vitesse de développement</strong>{" "}
          afin de réduire les coûts et produire une application plus pérenne, maintenable et performante dans le temps.
        </p>
        <Divider height={60} className="mt-4" />
        <h2 className="text-center">Dites nous en un peu plus sur votre projet</h2>
        <div className="flex items-center justify-center flex-wrap mt-8 mb-5 mx-6" style={{gap: 25}}>
          {config.map(i => 
            <Project key={i.name} title={i.name} icon={i.icon} onClick={() => _onProjectChange(i.name)} selected={i.name === project} />
          )}
        </div>
        {project ? <h2 className="text-center mt-16 mb-8">Technologies disponibles</h2> : <></>}
        <div id="technologiesContainer" className="flex items-center justify-center flex-wrap mb-8" style={{gap: 20}}>
          {project ? config.filter(i => i.name === project)[0].technos
                           .map(techno => technos.filter(i => i.name === techno)[0])
                           .map(techno => 
            <div className="" key={techno.name}>
              <img src={techno.img} className="" style={{width: 150}} />
            </div>
            ) : <></>}
        </div>
      </div>
      <Footer />
    </main>
  )
}

export default Solutions;